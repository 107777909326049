import {
  trackLoadEvent,
  trackViewEvent,
  trackDOMEvent,
} from '~/components/shared/tracking/segmentAnalytics';
const RESERVED_ATTRIBUTES = [
  'event',
  'view-event',
  'load-event',
  'load-action',
  'view-action',
  'manual-view',
  'manual-load',
];

function setUpDomEvents() {
  document.querySelectorAll('[data-segment-event]').forEach((el) => {
    if (typeof el.dataset.listeningForClick !== 'undefined') return;
    el.addEventListener('click', (event) => {
      trackDOMEventonEl(event, el);
    });
    el.dataset.listeningForClick = true;
  });
}

setUpDomEvents();

document.querySelectorAll('[data-segment-load-event]').forEach((el) => {
  if (typeof el.dataset.segmentManualLoad !== 'undefined') return;
  trackLoadEvent(el);
});

document.querySelectorAll('[data-segment-view-event]').forEach((el) => {
  // this property is used by modals since intersection observers don't respect invisible elements
  if (typeof el.dataset.segmentManualView !== 'undefined') return;
  const observer = createIntersectionObsv(el);
  observer.observe(el);
});

document
  .querySelectorAll('a[data-ga-event-tracking]')
  .forEach((el) =>
    el.addEventListener('click', (event) => trackDOMEvent(event)),
  );

document
  .querySelectorAll('button[data-ga-event-tracking]')
  .forEach((el) =>
    el.addEventListener('click', (event) => trackDOMEvent(event)),
  );

document
  .querySelectorAll('form[data-ga-event-tracking]')
  .forEach((el) =>
    el.addEventListener('submit', (event) => trackDOMEvent(event)),
  );

function createIntersectionObsv(el) {
  const observer = new IntersectionObserver(
    (records) => {
      for (const record of records) {
        if (record.isIntersecting) {
          trackViewEvent(el);
          observer.unobserve(el);
        }
      }
    },
    {
      threshold: [1, 0],
    },
  );
  return observer;
}

function getSegmentGlobbedProperties(el) {
  const globbedProperties = {};
  for (const key of el.getAttributeNames()) {
    if (key.startsWith('data-segment-')) {
      const rawKey = key.replace('data-segment-', '');
      if (RESERVED_ATTRIBUTES.includes(rawKey)) {
        continue;
      } else {
        const snakeCaseKey = rawKey.replaceAll('-', '_');
        globbedProperties[snakeCaseKey] = el.getAttribute(key);
      }
    }
  }
  return globbedProperties;
}

function trackDOMEventonEl(event, el) {
  let additionalProps = {};
  if (el.dataset.additionalSegmentPropsElement) {
    const additionalPropsEl = document.querySelector(
      el.dataset.additionalSegmentPropsElement,
    );
    if (additionalPropsEl) {
      additionalProps = getSegmentGlobbedProperties(additionalPropsEl);
    }
  } else {
  }
  trackDOMEvent(event, additionalProps);
}

export { setUpDomEvents, getSegmentGlobbedProperties };
